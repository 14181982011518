import React from 'react'
import './home.scss'
import VideoIntro from '../Components/LandingPageComponents/VideoIntro';
import selfi from '../images/selfi.jpg';
import Brain from '../images/brain.jpg';
import { Link } from 'react-router-dom';
import Nav from '../Components/MainPageComponents/Nav';
import Layout from '../Components/MainPageComponents/Layout';
// import MailchimpSubscriptionForm from '../Components/auth/MailchimpSubscriptionForm';
import { BsLinkedin } from "react-icons/bs";
import { BsYoutube } from "react-icons/bs";
import { GrContact } from "react-icons/gr";
import { BsCheck } from "react-icons/bs";

import Testemonials from '../Components/LandingPageComponents/Testemonials'
import CourseLeadFluency from '../Components/LandingPageComponents/CourseLeadFluency'
import Price from '../Components/LandingPageComponents/Price'
import VideoTestemonies from '../Components/LandingPageComponents/VideoTestemonies'
import LandingFooter from '../Components/LandingPageComponents/LandingFooter'
import EnrollBtn from '../Components/Buttons/EnrollBtn';
import CourseModules from '../Components/LandingPageComponents/CourseModules';
import { FaRegHandPointRight } from "react-icons/fa"
import BeforePrice from '../Components/LandingPageComponents/BeforePrice';
import CourseTools from '../Components/LandingPageComponents/CourseTools';
import MyProfile from '../Components/LandingPageComponents/MyProfile';
import NewsletterComponent from '../Components/Forms/NewsletterComponent';
import CourseSnippet from '../Components/CourseSnippet';
import EBook from '../Components/EBook';
import NewsletterSubscriptionForm from '../Components/Forms/NewsletterSubscriptionForm';



function LandingPage() {
    return (
        <div className=' justify-center items-center flex flex-col w-full lg:min-w-[1000px]   sm:gap-0  bg-white'>
            <div className='flex flex-col md:flex-row sm:flex-row  z-8 w-full '>
                <div className=' bg-gradient-to-r from-violet-600 to-indigo-600 text-dk-purple pt-8 pb-8 px-2 flex lg:flex-row 
            flex-col md:items-center center lg:items-center lg:justify-around  w-full' >
                    <div className='  lg:w-[50%] flex flex-col lg:gap-16 text-white gap-6 py-4 md:pl-6 lf:pr-0 m md:pr-6 rounded-9 '>
                        <div className='lg:pt-16 flex flex-col '>
                            <h1 className='text-2xl md:text-3xl font-bold '>E-book Gratuito!!</h1>
                            <h2 className='text-xl'>📘 "Desbloqueie sua Fluência em Inglês!"!</h2>
                            <h2 className='mt-6 mb-3' >Baixe o meu e-book GRATUITAMENTE e dê o próximo passo rumo à fluência! </h2>
                            <NewsletterSubscriptionForm button={"Receber meu e-book"} />
                        </div>
                        <MyProfile />
                    </div>
                    <EBook />
                </div>

            </div>

            <CourseLeadFluency />
            < CourseModules />
            <div id='' className='flex flex-col w-full justify-center items-center gap-6'>
                <CourseSnippet
                    courseVideo={'https://streamable.com/tez5i1'}
                    courseTitle={"Curso de Inglês Básico Completo!"}
                    courseDescription={"Aprenda vocabulário e domine as estruturas fundamentais do inglês. Isso lhe proporcionará uma base sólida no idioma!!"}
                    courseOriginalPrice="Mais informações"
                    salesMessage={"Aproveite as promoções da Udemy."}
                    courseLink={'https://www.udemy.com/course/curso-de-ingles-basico-adquira-uma-base-solida-no-idioma/?referralCode=DAD28CA36F4E068DB9E8'}
                    onSale={false}
                />
                <CourseSnippet
                    courseVideo={'https://vimeo.com/874380115'}
                    courseTitle={"Curso de Inglês Intermediário 1!"}
                    courseDescription={"Aprenda a falar sobre seus PLANOS, OBJETIVOS, DECISÕES e PREVISÕES para o futuro. Aulas dinâmicas e atividades práticas! "}
                    courseOriginalPrice="Apenas R$119,90"
                    salesMessage={"Qualidade excepcional por um valor acessível! (Parcele em até 4x)"}
                    courseLink={'https://pay.hotmart.com/Y60937283B'}

                />
                <CourseSnippet
                    courseVideo={'https://vimeo.com/874379824'}
                    courseTitle={"Curso de Inglês Intermediário 2!"}
                    courseDescription={"APRENDA A CONTAR HISTÓRIAS EM INGLÊS! Explicações claras, atividades de leitura, listening, speaking e muito mais!"}
                    courseOriginalPrice=" De R$159,99"
                    coursePromoPrice="por apenas R$89,99"
                    salesMessage={"Aproveite a nova promoçaõ antes que acabe! (Parcele em até 3x)"}
                    onSale={true}
                    courseLink={'https://pay.hotmart.com/D87533681U'}
                />
            </div>

            <Testemonials />
            <CourseTools />
            <VideoTestemonies />
            <div className='md:h-[20vh] h-[12vh]'></div>
            <LandingFooter />
            <NewsletterComponent />
        </div >


    )
}

export default LandingPage

