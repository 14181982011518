import React from 'react'
import { Link } from 'react-router-dom'


function Logo({ isShow }) {
    return (
        <div className={`relative w-[150px] ${isShow && "hidden"} mx-8`}>
            <Link to={'/'} className="text-gray-600 text-1.5xl tracking-wider ">
                <span className='absolute left-28 top-2.5 text-[.6rem]'>TEACHER</span>
                <h1 className='font-logo w-full text-[2.5rem] text-dk-blue  whitespace-nowrap '>Lane Antunes</h1>
            </Link>
        </div>
    )
}

export default Logo
