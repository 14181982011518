import React, { useState } from 'react';
import student from "./AvatarTest.png";
import fiveStars from "./fiveStars.png";
import seeMoreIcon from "./seeMoreIcon.svg";
import { FiArrowRightCircle } from "react-icons/fi"
import letterE from "./letterE.png";
import letterF from "./letterF.png";
import letterL from "./letterL.png";
import letterI from "./letterI.png";
import letterR from "./letterR.png";
import letterS from "./letterS.png";
import letterY from "./letterY.png";
import letterP from "./letterP.png";
import letterN from "./letterN.png";
import letterT from "./letterN.png";

function Rectangle({ avatar, name, review, stars }) {
    return (
        <div className="rounded-[40px] flex shadow-md bg-white p-4 gap-3">
            <img src={avatar} alt='student' className='w-[40px] h-[40px]' />
            <div>
                <h3 className='font-bold text-py-purple text-sm md:text-base'>{name}</h3>
                <p className='pb-3 text-sm  text-mid-gray py-3'>{review}</p>
                <img src={stars} alt='stars' />
            </div>
        </div>
    )
}

function Testemonials() {
    const [numRectangles, setNumRectangles] = useState(4); // Initialize with 5 rectangles

    // Define your rectangles data
    const reviewData = [
        {
            stars: fiveStars,
            avatar: letterE,
            name: 'Elvis Lima Neves',
            review: 'Ótimas explicações, ótima didática, lições práticas e com bons exemplos, a dicção da professora é fantástica facilitando o entendimento na hora de repetir as frases ou uma palavra, fora que a professora da uns toques de ferramentas para estudo incríveis, parabéns professora, recomendo total.',
        },

        {
            stars: fiveStars,
            avatar: letterP,
            name: 'Paulo Cesar da Silva Alcantara',
            review: 'O Curso é perfeito, fornece todos os requisitos necessários para o aprendizado do inglês. Fornece material escrito, trabalha pronúncia e audição. Dicas excelentes e os Flashcards é muito bom para a consolidação do conhecimento da língua.',
        },

        {
            stars: fiveStars,
            avatar: letterY,
            name: 'Yuri Miranda Boechat',
            review: 'Olha, além da didática excelente, a organização da Lane é admirável. Tudo extremamente explicado, tudo organizado passo a passo, seguindo uma ordem lógica e cativante. Nota 10 para este curso.',
        },
        {
            stars: fiveStars,
            avatar: letterT,
            name: 'Thais Luana Consoni Costa',
            review: 'Achei o curso maravilhoso. Didática, conteúdo, vídeos, slides, explicações... Tudo muito bom e de forma simples. Recomendo, nota !!!.',
        },

        {
            stars: fiveStars,
            avatar: letterF,
            name: 'Fernando Damasceno',
            review: 'A Lane é fascinante! A didática dela é única e as aulas dela são de muito fácil entendimento. Ela consegue nos ensinar todo o inglês de maneira extremamente simples, objetiva e descomplicada.',
        },

        {
            stars: fiveStars,
            avatar: letterR,
            name: 'Rui Rodrigues 4.9',
            review: 'É um ótimo curso para quem quer iniciar os estudos na língua inglesa. A prof Lane explica muito bem,o que eu não aprendi em anos de escola, em 15 minutinhos(ou menos) aprendi nas aulas deste curso. Super recomendo! Super cool!!',
        },
        {
            stars: fiveStars,
            avatar: letterS,
            name: 'Samuel Nunes Martins',
            review: 'Estou gostando bastante da didática da professora, claramente tem experiência no que faz e guia muito bem as aulas e lições. Nota 10.',
        },
        {
            stars: fiveStars,
            avatar: letterN,
            name: 'Nilseia Ribeiro Teixeira',
            review: 'Curso maravilhoso e com exercícios. O professor tem domínio sobre o assunto e as aulas são muito bem explicadas. Valeu apena o investimento!',
        },
        {
            stars: fiveStars,
            avatar: letterL,
            name: 'Lucas Rodrigues Freitas',
            review: 'Sim, o curso é o que eu esperava! na verdade até superou minhas expectativas. Eu jpa tinha feito um curso de inglês antes, mas fiquei muito tempo sem praticar e esse curso tem me ajudado! parabéns a professora Lane, por contribuir para o aprendizado de inglês no Brasil',
        },
        {
            stars: fiveStars,
            avatar: letterE,
            name: 'Elio Elio Alves',
            review: 'Estou adorando, a professora é muito organizada e estou adorando as propostas de atividades e de organização de estudo.',
        },
        {
            stars: fiveStars,
            avatar: letterI,
            name: 'Isabelle Cristina Dias Arena',
            review: 'Essas dicas de sites e cards para estudar foram MARAVILHOSAS! Eu nunca tinha ouvido falar e fiquei mega ansiosa para usar todas!',
        },
        {
            stars: fiveStars,
            avatar: letterF,
            name: 'Flavio Travincas',
            review: 'Até o momento é bem cativante. Vejo que o curso vai ser bem interessante. Epero aproveitar bastante, pois farei mais até meu objetivo ser atingido.',
        },
        // Add more rectangle data objects here...
    ];

    const [currentIndex, setCurrentIndex] = useState(0);

    // Define the number of reviews to display based on screen size
    const numReviewsToShow = window.innerWidth <= 768 ? 2 : 4;

    const displayReviews = reviewData.slice(currentIndex, currentIndex + numReviewsToShow).map((data, index) => (
        <Rectangle
            key={index}
            stars={data.stars}
            avatar={data.avatar}
            name={data.name}
            review={data.review}
        />
    ));

    const handleSeeMoreClick = () => {
        // Calculate the next index to display the next set of reviews
        const nextIndex = currentIndex + numReviewsToShow;

        // If there are more reviews to display, update the currentIndex
        if (nextIndex < reviewData.length) {
            setCurrentIndex(nextIndex);
        } else {
            // If we've reached the end of reviews, reset to the beginning
            setCurrentIndex(0);
        }
    };

    return (
        <div className='  pb-16 px-6 text-xl lg:px-36 bg-white'>
            <h1 className='pb-10 md:pb-16 pt-16 font-bold md:text-2xl text-center text-dk-purple'>O que os alunos estão achando dos cursos!</h1>
            <div className='flex flex-col md:grid md:grid-cols-2 place-items-center gap-3 md:gap-8 '>
                {displayReviews}
            </div>
            <div className='w-full flex items-center justify-center pt-10  '>
                <button onClick={handleSeeMoreClick} className=' transition-transform duration-200 ease-in-out transform hover:scale-125'>

                    <FiArrowRightCircle style={{ fontSize: '40px', color: '#3e1385' }} />
                </button>
            </div>
        </div>
    )
}

export default Testemonials;