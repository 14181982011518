import React from "react"
import ReactPlayer from "react-player"

function CourseSnippet({
  courseTitle,
  courseDescription,
  courseVideo,
  courseLink,
  courseOriginalPrice,
  coursePromoPrice,
  onSale = false,
  salesMessage,
}) {
  return (
    <div
      id="price-section"
      className="lg:px-10 px-4 lg:py-10 border pb-4 flex flex-col lg:gap-6 justify-center items-center w-full rounded-9 text-white bg-gradient-to-r from-violet-600 to-indigo-600"
    >
      <div className=" w-full flex justify-center">
        <div className="lg:hidden rounded-9 overflow-hidden">
          <ReactPlayer
            url={courseVideo}
            width={"360px"}
            height={"220px"}
            controls={true}
          />
        </div>
        <div className="hidden lg:flex justify-center rounded-9 overflow-hidden">
          <ReactPlayer url={courseVideo} controls={true} />
        </div>
      </div>
      <div className=" flex justify-center items-center w-full">
        <div className="flex flex-col justify-center items-center w-full lg:w-[50%] text-sm">
          <h2 className="text-xl text-center font-bold lg:text-start pb-6 pt-4">
            {courseTitle}
          </h2>
          <h2 className="lg:w-[80%] text-center lg:text-start">
            {courseDescription}
          </h2>

          <div className="border border-py-gray rounded-9 p-4 lg:w-[80%] mt-4">
            <h3>✔️ Flashcards personalizados.</h3>
            <h3>✔️ Apostila com textos e exercícios.</h3>
            <h3>✔️ Videoaulas completas.</h3>
            <h3>✔️ Exercícios interativos.</h3>
            <h3>✔️ Suporte completo da professora.</h3>
          </div>
          {onSale && (
            <>
              <a
                href={`${courseLink}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div
                  className={`mt-4 p-2  rounded-lg  bg-py-orange shadow-lg text-sm font-semibold text-center cursor-pointer hover:bg-white`}
                >
                  Oferta Especial:
                  <span className="line-through text-gray-300 mr-2 hover:text-white ">
                    {" "}
                    {courseOriginalPrice}
                  </span>
                  {coursePromoPrice}
                </div>
              </a>
              <p className="mt-2 text-sm font-medium text-center lg:text-start  text-orange-200 italic">
                {salesMessage}
              </p>
            </>
          )}
          {!onSale && (
            <>
              <a
                href={`${courseLink}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div
                  className={`mt-4 p-2  text-center  rounded-lg shadow-lg text-sm font-semibold bg-white cursor-pointer  hover:bg-py-orange`}
                >
                  <span className=" text-py-orange text-center mr-2 hover:text-white ">
                    {" "}
                    {courseOriginalPrice}
                  </span>
                </div>
              </a>
              <p className="mt-2 text-sm text-center lg:text-start font-medium  text-orange-200 italic">
                {salesMessage}
              </p>
            </>
          )}

          <p className="italic text-sm">
            Você será redirecionado a plataforma Udemy.com
          </p>
          {/* <a className="pt-4 underline italic text-sm text-center lg:text-start hover:text-py-orange" target="_blank" rel="noopener noreferrer" href={`${courseLink}`}>Acessar a plataforma</a> */}
        </div>
      </div>
    </div>
  )
}

export default CourseSnippet
