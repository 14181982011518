import React from 'react'
import NewsletterSubscriptionForm from './NewsletterSubscriptionForm'

function NewsletterComponent() {
    return (
        <div className='flex gap-6 py-16  flex-col items-center justify-center w-full border bg-custom-gray '>
            <div className='flex flex-col gap-1 text-center'>
                <p className='text-base font-bold '>Inscreva-se na nossa newsletter! </p>
                <p className='text-sm  '>Você receberá dicas estratégicas para acelerar o seu aprendizado em inglês de maneira eficaz e sem perder tempo.</p>
            </div>
            <NewsletterSubscriptionForm button={"Inscrever-se"} />
        </div>
    )
}

export default NewsletterComponent