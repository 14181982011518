import React from "react"
import "./QuizGame.scss"
import { useState, useEffect } from "react"
import Stats from "./Stats"
import EndScreen from "./EndScreen"
import TriviaItem from "./TriviaItem"
import useScores from "../../Hooks/useScores"
import Ad1 from "../../Ads/Ad1"
import inter1 from "./../../../images/inter1.png"
import inter2 from "./../../../images/inter2.png"
import freeEbook from "./../../../images/freeEbook.png"

function QuizGame({ quizData, quizId, userId }) {
  const [status, setStatus] = useState("loading")
  const [explanationPopup, setExplanationPopup] = useState("")
  const [answerWasCorrect, setAnswerWasCorrect] = useState("")
  // const { saveScore } = useScores(quizId);

  const [scoreData, setScoreData] = useState(null)
  const { saveScore, scoresArray } = useScores(quizId)

  useEffect(() => {
    if (scoreData) {
      saveScore(quizId, scoreData)
    }
  }, [scoreData])

  const [gameState, setGameState] = useState({
    score: 0,
    triviaIndex: 0,
    isGameOver: false,
  })
  useEffect(() => {
    if (quizData) {
      setStatus("loaded")
    }
  }, [quizData])

  if (status === "loading") {
    return <div>Loading...</div>
  }

  const { score, triviaIndex, isGameOver } = gameState

  const questionNumber = triviaIndex + 1
  const numbQuestions = quizData.questions.length

  const onRestartGame = () => {
    setGameState({
      score: 0,
      triviaIndex: 0,
      isGameOver: false,
    })
  }

  const onLoadNextQuestion = () => {
    setExplanationPopup("")
    setAnswerWasCorrect("")
    if (triviaIndex >= numbQuestions - 1) {
      setGameState({
        ...gameState,
        isGameOver: true,
      })
      const dataToSend = {
        id: quizId,
        score: [score],
        title: quizData.title,
        level: quizData.level,
        numbQuestions: quizData.questions.length,
      }
      setScoreData(dataToSend)
    } else {
      setGameState({
        //copy gamestate and overide triviaIndex//
        ...gameState,
        triviaIndex: triviaIndex + 1,
      })
    }
  }

  const triviaQuestion = quizData.questions[triviaIndex]
  const { explanation } = triviaQuestion

  const onAnswerSelected = (wasPlayerCorrect) => {
    setExplanationPopup(explanation)

    if (wasPlayerCorrect) {
      setAnswerWasCorrect(true)
      setGameState({
        ...gameState,
        score: score + 1,
      })
    }
  }

  let pageContent

  if (isGameOver) {
    pageContent = (
      <EndScreen
        score={score}
        onRetryClick={onRestartGame}
        quizId={quizId}
        userId={userId}
        quizData={quizData}
        totalQuestions={numbQuestions}
      />
    )
  } else {
    const triviaQuestion = quizData.questions[triviaIndex]
    const { correctAnswer, incorrectAnswers, question, explanation } =
      triviaQuestion
    pageContent = (
      <TriviaItem
        key={triviaIndex}
        question={question}
        correctAnswer={correctAnswer}
        incorrectAnswers={incorrectAnswers}
        onNextClick={onLoadNextQuestion}
        onAnswerSelected={onAnswerSelected}
        explanation={explanationPopup}
        answerWasCorrect={answerWasCorrect}
      />
    )
  }

  return (
    <>
      <h1 className="border-t  quizName pt-5 sm:pt-16 font-bold pb-5 text-base sm:text-lg">
        {quizData.title}
      </h1>

      <div className="flex justify-center flex-col gap-6 items-center lg:items-start w-full lg:min-h-[90vh]  bg-dk-blue">
        {/* <div className='hidden text-xs pt-16  lg:flex gap-6 lg:flex-col justify-center items-center lg:w-[20vw] '>
                    <Ad1 prodImage={inter1} prodTitle={"Curso Intermediário 1"} prodLink={'https://pay.hotmart.com/Y60937283B'} onSale={false} imagewith={"150px"} internalLink={false} />
                    <Ad1 prodImage={inter2} prodTitle={"Curso Intermediário 2"} prodLink={'https://pay.hotmart.com/D87533681U'} onSale={true} imagewith={"150px"} internalLink={false} />

                </div> */}
        <div className="quiz-game-questions pb-16 w-full">
          <Stats
            score={score}
            questionNumber={questionNumber}
            totalQuestions={numbQuestions}
          />
          {pageContent}
        </div>
        <div className=" w-full text-sm pb-8 flex justify-center ">
          <Ad1
            prodImage={freeEbook}
            prodTitle={" Ebook - GRATUITO!"}
            prodLink={"/"}
            onSale={false}
            imagewith={"250px"}
            internalLink={true}
          />
        </div>
        {/* show only on Mobile */}
        {/* <div className='flex flex-col lg:hidden w-[full] gap-6 pb-16 text-base '>
                    <Ad1 prodImage={freeEbook} prodTitle={" Ebook - GRATUITO!"} prodLink={'/'} onSale={false} imagewith={"250px"} internalLink={true} />
                    <Ad1 prodImage={inter1} prodTitle={"Curso Intermediário 1"} prodLink={'https://pay.hotmart.com/Y60937283B'} onSale={false} imagewith={"250px"} internalLink={false} />
                    <Ad1 prodImage={inter2} prodTitle={"Curso Intermediário 2"} prodLink={'https://pay.hotmart.com/D87533681U'} onSale={true} imagewith={"250px"} internalLink={false} />
                </div> */}
      </div>
    </>
  )
}

export default QuizGame
