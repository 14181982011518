import React from 'react'

function LandingFooter() {
    const scrollToPriceSection = (event) => {
        event.preventDefault(); // Prevent the default behavior of the anchor tag

        const priceSection = document.getElementById('price-section');

        if (priceSection) {
            priceSection.scrollIntoView({ behavior: 'smooth' });
        }
    };
    return (
        <>
            <div className='bg-[url("./images/background4.png")] flex justify-between gap-4 items-center w-full lg:px-16 px-6 py-6 text-white'>
                <p className='text-sm md:text-base'>Dominar o inglês não é apenas uma habilidade, mas um caminho para oportunidades ilimitadas. Faça a escolha certa para o seu crescimento pessoal e profissional!"</p>
                <a href="#"
                    onClick={scrollToPriceSection} className='transition-transform duration-400 ease-in-out hover:scale-105 md:py-4 p-3 text-center text-xs md:text-base md:px-8 bg-py-orange bg-gradient-to-r from-orange-500 to-orange-600 rounded-xl md:rounded-[36px] '> Garanta já sua vaga!</a>
            </div>

        </>
    )
}

export default LandingFooter