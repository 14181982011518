import React, { useEffect, useState } from 'react';
import seeMoreIcon from "./seeMoreIcon.svg";
import { FiArrowRightCircle } from "react-icons/fi"
// import { getDownloadURL } from 'firebase/storage';
// import {
//     storage, videoShortFlashcards, videoShortReading, videoShortListening, videoShortSpeaking, videoShortPronunciation, videoShortPtExplanation, videoShortEnExplanation, videoShortExercises
// } from '../../firebase'; // Adjust the path based on your file structure
import ReactPlayer from 'react-player';



function Exercises({ video, button }) {
    return (
        // <div className="flex flex-col w-[250px] shadow-md bg-custom-gray rounded-xl items-center ">
        //     <iframe className=' rounded-t-9 w-full h-[22vh]' src={video} title=" video player" frameBorder="0" allow="accelerometer; fullscreen; gyroscope;"></iframe>
        //     <div className=' text-white text-center  text-xs md:text-sm  p-3  uppercase rounded-b-lg pb-3 w-full bg-dk-purple'>{button}</div>
        // </div>
        <div className=' w-full px-1 pt-4 bg-gradient-to-r from-dk-purple to-py-purple'>
            <ReactPlayer
                url={video}
                width={'100%'}
                height={'100%'}
            />
            <div className=' text-white text-center  text-xs lg:text-sm pt-3  lg:p-3  uppercase rounded-b-lg pb-3 w-full bg-dk-purple'>{button}</div>
        </div>
    )
}

function CourseTools() {
    const [numExercises, setNumExercises] = useState(4);
    const [currentIndex, setCurrentIndex] = useState(0);

    // const [videoUrls, setVideoUrls] = useState({
    //     video1: null,
    //     video2: null,
    //     video3: null,
    //     video4: null,
    //     video5: null,
    //     video6: null,
    //     video7: null,
    //     video8: null,
    // });

    // useEffect(() => {
    //     // Fetch the first video URL
    //     getDownloadURL(videoShortFlashcards)
    //         .then((url1) => {
    //             // Set the first video URL in the state
    //             setVideoUrls((prevUrls) => ({ ...prevUrls, video1: url1 }));
    //         })
    //         .catch((error) => {
    //             console.error('Error getting download URL for video1:', error);
    //         });

    //     // Fetch the second video URL
    //     getDownloadURL(videoShortReading)
    //         .then((url2) => {
    //             // Set the second video URL in the state
    //             setVideoUrls((prevUrls) => ({ ...prevUrls, video2: url2 }));
    //         })
    //         .catch((error) => {
    //             console.error('Error getting download URL for video2:', error);
    //         });
    //     getDownloadURL(videoShortSpeaking)
    //         .then((url3) => {
    //             // Set the first video URL in the state
    //             setVideoUrls((prevUrls) => ({ ...prevUrls, video3: url3 }));
    //         })
    //         .catch((error) => {
    //             console.error('Error getting download URL for video1:', error);
    //         });
    //     getDownloadURL(videoShortPtExplanation)
    //         .then((url4) => {
    //             // Set the first video URL in the state
    //             setVideoUrls((prevUrls) => ({ ...prevUrls, video4: url4 }));
    //         })
    //         .catch((error) => {
    //             console.error('Error getting download URL for video1:', error);
    //         });
    //     getDownloadURL(videoShortEnExplanation)
    //         .then((url5) => {
    //             // Set the first video URL in the state
    //             setVideoUrls((prevUrls) => ({ ...prevUrls, video5: url5 }));
    //         })
    //         .catch((error) => {
    //             console.error('Error getting download URL for video1:', error);
    //         });
    //     getDownloadURL(videoShortExercises)
    //         .then((url6) => {
    //             // Set the first video URL in the state
    //             setVideoUrls((prevUrls) => ({ ...prevUrls, video6: url6 }));
    //         })
    //         .catch((error) => {
    //             console.error('Error getting download URL for video1:', error);
    //         });
    //     getDownloadURL(videoShortPronunciation)
    //         .then((url7) => {
    //             // Set the first video URL in the state
    //             setVideoUrls((prevUrls) => ({ ...prevUrls, video7: url7 }));
    //         })
    //         .catch((error) => {
    //             console.error('Error getting download URL for video1:', error);
    //         });
    //     getDownloadURL(videoShortListening)
    //         .then((url8) => {
    //             // Set the first video URL in the state
    //             setVideoUrls((prevUrls) => ({ ...prevUrls, video8: url8 }));
    //         })
    //         .catch((error) => {
    //             console.error('Error getting download URL for video1:', error);
    //         });
    // }, []); // Note: Empty dependency array to run useEffect only once



    const exerciseData = [
        {
            video: "https://streamable.com/wmgfbi",

            button: "Flashcards",
        },
        {
            video: "https://streamable.com/mnibow",

            button: "Speaking Practice",
        },

        {
            video: "https://streamable.com/onxl69",

            button: "Explicações em português",
        },
        {
            video: "https://streamable.com/ldpju3",

            button: "Explicações em inglês",
        },
        {
            video: "https://streamable.com/mjpkh7",

            button: "Leitura",
        },
        {
            video: "https://streamable.com/13qpu2",

            button: "Exercícios",
        },
        {
            video: "https://streamable.com/875z4a",

            button: "Aulas de pronúncia",
        },
        {
            video: "https://streamable.com/5pffup",

            button: "Exercício de escuta",
        },

        // Add more rectangle data objects here...
    ];

    const numExercisesToShow = 4;

    const displayExercises = exerciseData.slice(currentIndex, currentIndex + numExercisesToShow).map((data, index) => (
        <Exercises
            key={index}
            video={data.video}

            button={data.button}
        />
    ));

    const handleSeeMoreClick = () => {
        // Calculate the next index to display the next set of exercises
        const nextIndex = currentIndex + numExercisesToShow;

        // Check if there are more exercises to display
        if (nextIndex < exerciseData.length) {
            setCurrentIndex(nextIndex);
        } else {
            // If it's reached the end of exercises, reset to the beginning
            setCurrentIndex(0);
        }
    };

    return (
        <div className='md:gap-32 w-full gap-16 flex flex-col pt-24   bg-gray-100 '>

            <h1 className='text-center px-6 md:px-16 font-bold text-base md:text-xl text-dk-purple '>
                São diversas atividades que vão te permitir estudar o mesmo conteúdo de várias formas, e isso pode ser feito de qualquer lugar, até mesmo no trânsito ou enquanto arruma a casa  😲 </h1>
            <div className='flex flex-col shadow-md  '>
                <div className=' flex flex-col md:flex-row items-center gap-8 md:gap-1 justify-center'>
                    {displayExercises}
                </div>
                <div className='w-full text-center items-center justify-center pt-16 pb-12  '>
                    <button onClick={handleSeeMoreClick} className='transition-transform duration-200 ease-in-out transform hover:scale-125'>
                        <FiArrowRightCircle style={{ fontSize: '40px', color: '#472347' }} />
                    </button>
                </div>
            </div>
        </div>
    )
}

export default CourseTools

