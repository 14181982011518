import React, { useEffect } from "react"
import { Link } from "react-router-dom"

function Ad1({
  prodImage,
  prodTitle,
  promoPrice,
  prodDescription,
  onSale = false,
  prodLink,
  imagewith,
  internalLink = false,
}) {
  const isInternalLink = internalLink ? (
    <Link
      className=" pt-1 text-blue-100 text-center italic underline cursor-pointer hover:text-sm"
      to={prodLink}
    >
      {prodTitle}
    </Link>
  ) : (
    <a
      className=" pt-1 text-blue-100 text-center italic underline cursor-pointer hover:text-sm"
      target="_blank"
      rel="noopener noreferrer"
      href={`${prodLink}`}
    >
      {prodTitle}
    </a>
  )

  return (
    <>
      {onSale && (
        <div className="text-center flex-col">
          <div className="relative">
            <h1 className="bg-py-orange absolute  text-white p-1">Promoção!</h1>
            <img src={prodImage} width={imagewith} alt="imagem do curso" />
          </div>
          <div className="pt-3">{isInternalLink}</div>
        </div>
      )}
      {!onSale && (
        <div className="flex-col gap-3 text-center">
          <img src={prodImage} width={imagewith} alt="imagem do curso" />
          <div className="pt-3">{isInternalLink}</div>
        </div>
      )}
    </>
  )
}

export default Ad1
