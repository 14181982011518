import React from 'react'
import IconH1P from '../IconText/IconH1P'
import photo from "./photo.png"
import icon1 from "./icon1.svg"
// import icon3 from "./icon3.svg"
// import IconH1P from '../IconText/IconH1P'
// import IconH1P from "../Components/IconText/IconH1P"
// import icon1 from "../images/icon1.svg"
// import icon4 from "../images/icon4.svg"
// import icon2 from "../images/icon2.svg"
import icon3 from "./icon3.svg"

function MyProfile() {

    return (
        <div className='mt-2 flex gap-2 md:gap-4 items-center  '>

            <div class="rounded-full overflow-hidden w-[180px] md:w-[200px] h-[25vh]  lg:h-[35vh] md:h-[30vh]  border-b md:m-2 ">
                <img src={photo} alt="Profile Picture" class="w-full h-full object-cover " />
            </div>
            <div className='  flex flex-col items-start justify-center border rounded-9 md:border-none  py-2'>
                <h1 className='p-2 font-bold '>Sobre a prof.</h1>
                <p className='font-bold pb-2 px-2 md:text-xm lg:text-sm text-xs'>13+ anos de experiência ministrando aulas de inglês para adultos.</p>
                <div className='flex flex-col text-xs px-3'>
                    <p>3 anos - Escolas de idiomas Wise Up e Compahia de idiomas.</p>
                    <p>4 anos - Aulas particulares in-company em São Paulo.</p>
                    <p>6+ anos - Aulas particulares online e criação de conteúdo.</p>
                </div>
                <p className='font-bold pt-3 px-2 md:text-xm lg:text-sm text-xs '>3 anos - Ensino médio nos Estados Unidos</p>
                <div className='px-2 flex flex-wrap  gap-3  md:gap-4 pt-4 md:w-full '>
                    <IconH1P pic={icon1} title={'3,000+'} description={"Alunos inscritos"} />
                    {/* <IconH1P pic={icon4} title={'600+'} description={"Avaliações positivas"} /> */}
                    <IconH1P pic={icon3} title={'402,294+'} description={"Minutos ensinados"} />
                    {/* <IconH1P pic={icon2} title={'13+ Anos'} description={"Experiência da prof."} /> */}
                </div>
            </div>
        </div>
    )
}

export default MyProfile