import React, { useEffect, useState } from 'react';
import { getDownloadURL } from 'firebase/storage';
import { storage, videoVitor, videoRaissa } from '../../firebase'; // Adjust the path based on your file structure
import ReactPlayer from 'react-player';

function VideoTestemonies() {
    const [videoUrls, setVideoUrls] = useState({
        video1: null,
        video2: null,
    });

    useEffect(() => {
        // Fetch the first video URL
        getDownloadURL(videoVitor)
            .then((url1) => {
                // Set the first video URL in the state
                setVideoUrls((prevUrls) => ({ ...prevUrls, video1: url1 }));
            })
            .catch((error) => {
                console.error('Error getting download URL for video1:', error);
            });

        // Fetch the second video URL
        getDownloadURL(videoRaissa)
            .then((url2) => {
                // Set the second video URL in the state
                setVideoUrls((prevUrls) => ({ ...prevUrls, video2: url2 }));
            })
            .catch((error) => {
                console.error('Error getting download URL for video2:', error);
            });
    }, []); // Note: Empty dependency array to run useEffect only once

    return (
        <div className='flex flex-col items-center justify-center'>
            <h1 className='pb-16 md:pt-3 md:pb-24 font-bold text-2xl md:text-4xl text-center'>Depoimentos</h1>
            <div className='flex md:flex-row flex-col gap-3 md:w-full lg:h-[60vh] items-center justify-center md:px-24 px-6'>
                <div className='flex flex-col items-center justify-center '>
                    <ReactPlayer
                        url={videoUrls.video1}
                        controls
                        width="100%" // You can set the width as needed
                        height="100%" // You can set the height as needed
                    />
                    <h2 className='text-xs md:text-base italic pb-4 pt-2'>Vitor Melo - Programador - Rio de Janeiro</h2>
                </div>

                <div className='flex flex-col items-center justify-center'>
                    <ReactPlayer
                        url={videoUrls.video2}
                        controls
                        width="100%" // You can set the width as needed
                        height="100%" // You can set the height as needed
                    />
                    <h2 className='text-xs md:text-base italic pb-4 pt-2'>Raissa - Universitária - Portugal</h2>
                </div>

            </div>


        </div >
    );
}

export default VideoTestemonies;






//     < div className = ' flex flex-col items-center justify-center md:grid md:grid-cols-2 md:place-items-center gap-3' >
// <StudentVideo video={'https://www.youtube.com/embed/L_1DO1K6Jug?si=8EGmDexhTPyjqXUn'} />
// <StudentVideo video={'https://www.youtube.com/embed/L_1DO1K6Jug?si=8EGmDexhTPyjqXUn'} />
// <StudentVideo video={'https://www.youtube.com/embed/L_1DO1K6Jug?si=8EGmDexhTPyjqXUn'} />
// <StudentVideo video={'https://www.youtube.com/embed/L_1DO1K6Jug?si=8EGmDexhTPyjqXUn'} />
// </div >


// function StudentVideo({ video }) {

//     return (
//         <div className=" shadow-md bg-white  gap-3 rounded-xl items-center ">
//             <iframe className=' rounded-9 md:w-[40vw] md:h-[50vh] ' src={video} title="YouTube video player" frameBorder="0" allow="accelerometer;  gyroscope;"></iframe>
//         </div>
//     )
// }