import React from "react"
import freeEbook from "../images/freeEbook.png"

function EBook() {
  return (
    <img
      src={freeEbook}
      alt="Foto do ebook"
      className="sm:w-[350px] w-[250px] self-center pt-6 lg:pt-0 "
    />
  )
}

export default EBook
