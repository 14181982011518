import React from "react"
import { Link } from "react-router-dom"
import CookieConsent from "react-cookie-consent"
import NewsletterSubscriptionForm from "../Forms/NewsletterSubscriptionForm"

function AllLinks({ link, path }) {
  return (
    <li className="text-sm">
      <Link to={path}>{link}</Link>
    </li>
  )
}

function AllUrls({ urlName, path }) {
  return (
    <a className="text-sm" href={path} target="_blank">
      {urlName}
    </a>
  )
}

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  })
}

function Footer() {
  return (
    <footer>
      <div className="bg-dk-gray text-white py-6 pt-16 pb-2">
        <div className="flex flex-col justify-center items-center gap-10 sm:flex sm:flex-row sm:justify-around ">
          <ul className="flex flex-col gap-3 text-center">
            <h3 className="text-base font-semibold mb-2">Cursos Online</h3>
            <AllUrls
              urlName="Curso 1 - Básico Completo"
              path="https://www.udemy.com/course/curso-de-ingles-basico-adquira-uma-base-solida-no-idioma/?referralCode=DAD28CA36F4E068DB9E8"
            />
            <AllUrls
              urlName="Curso 2 - Intermediário 1"
              path="https://www.udemy.com/course/curso-de-ingles-intermediario-90-em-ingles-com-legendas/?referralCode=CAADBF2564957289B935"
            />
            <AllUrls
              urlName="Curso 3 - Intermediário 2"
              path="https://www.udemy.com/course/curso-de-ingles-intermediario-2-lane-antunes/?referralCode=EB310BD715B67485024A"
            />
          </ul>
          <ul className="flex flex-col gap-3 text-center">
            <h3 className="text-base font-semibold mb-2">Quick Links</h3>

            {/* <AllLinks link="Contato" path="/contact" /> */}
            <Link to="/grammar">
              <li className="cursor-pointer text-sm" onClick={scrollToTop}>
                Quizzes
              </li>
            </Link>
            <Link to="/">
              <li className="cursor-pointer text-sm" onClick={scrollToTop}>
                Home
              </li>
            </Link>
            <Link to="/login">
              <li className="cursor-pointer text-sm" onClick={scrollToTop}>
                Criar conta
              </li>
            </Link>
          </ul>
          <ul className="flex flex-col gap-3 text-center">
            <h3 className="text-base font-semibold mb-2">Contact teacher</h3>
            {/* <AllUrls
              urlName="Linkedin"
              path="https://www.linkedin.com/in/lane-antunes-57a07690/"
            /> */}
            <AllUrls
              urlName="Youtube"
              path="https://www.youtube.com/channel/UCQEeN8e0GP1utzQi0luuJOw"
            />
            <li className="text-sm">Email: lanenglisht@gmail.com</li>
          </ul>
        </div>
        <CookieConsent
          location="bottom"
          buttonText="Aceitar"
          cookieName="myCookieConsent"
          style={{ background: "#2B373B" }}
          buttonStyle={{
            background: "#3970b4",
            color: "#f5f5f5",
            fontSize: "13px",
          }}
          expires={365}
        >
          Este site utiliza cookies para melhorar a experiência do usuário. Ao
          usar este site, você concorda com o uso de cookies..
        </CookieConsent>
        <p className="text-center mt-12 text-sm">
          &copy; 2023 TeacherLaneAntunes.com.br Todos os direitos reservados.
        </p>
      </div>
    </footer>
  )
}

export default Footer
