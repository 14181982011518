import React from 'react'
import { AiFillCheckCircle } from "react-icons/ai";

function EachStrategy({ strategy }) {
    return (
        <div className='flex flex-col  gap-2 w-full  py-1 md:py-3'>
            <div className='flex  gap-3 border p-4  bg-custom-gray rounded-9'>
                <div>     <AiFillCheckCircle style={{ color: "#62A93F" }} /></div>
                <p className='  text-xs md:text-base text-black '>{strategy}</p>
            </div>
        </div >
    )
}

function CommunicationStrategies() {
    return (
        <div className=' flex flex-col justify-center md:flex-row  px-9  pb-16 ' >
            <div className=' md:gap-6  md:p-8 md:w-[40%]'>
                <EachStrategy strategy={"Existe sempre uma forma mais simples de dizer a mesma coisa."} />
                <EachStrategy strategy={"O Português pode ser uma ótima ferramenta para aprender o inglês."} />
                <EachStrategy strategy={"Inicie suas frases pelo sujeito."} />
                <EachStrategy strategy={"Fique fluente em um assunto antes de passar para o próximo."} />

            </div>
            <div className=' md:gap-6  md:p-8 md:w-[40%]'>
                <EachStrategy strategy={"Traduza a informação, não as palavras."} />
                <EachStrategy strategy={"Direcione a conversa para os assuntos que você domina."} />
                <EachStrategy strategy={"É mais fácil manter a coerencia com frases curtas."} />
                <EachStrategy strategy={"Concentre-se no tempo verbal em que sua história se passa."} />
            </div>

        </div>
    )
}

export default CommunicationStrategies