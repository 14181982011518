import React from "react"
import numOne from "./numOne.png"
import numTwo from "./numTwo.png"
import numThree from "./numthree.png"
import LearningFirst from "./LearningFirst"
import CommunicationStrategies from "./CommunicationStrategies"
import CourseTools from "./CourseTools"

function CourseLeadFluency() {
  return (
    <div className="md:px-2 px-4 py-6 md:py-0 w-full bg-gradient-to-r from-violet-600 to-indigo-600 text-dk-purple contain bg-center flex flex-col gap-8 items-center">
      <h1 className="w-fulpy-10 text-white  px-3 md:py-24 font-bold text-xl md:text-2xl lg:text-3xl text-center">
        Como ficar fluênte sem perder tempo?
      </h1>

      <div className="bg-white  md:w-[70%] rounded-xl text-dk-purple px-8 md:px-14 py-9 flex flex-col gap-8  shadow-md drop-shadow-blueish">
        <div className="flex items-center gap-2 justify-center ">
          <img src={numOne} className="w-[55px] md:w-[78px]" />
          <h2 className="text-base md:text-2xl font-bold">
            Aprenda o mais importante PRIMEIRO!
          </h2>
        </div>
        <p className="text-sm md:text-xl text-center lg:px-16">
          A língua inglesa possui um vasto vocabulário com mais de{" "}
          <scan className="font-bold text-base md:text-2xl">500.000 </scan>{" "}
          palavras, no entanto, cerca de{" "}
          <scan className="font-bold text-base md:text-2xl">80% </scan>
          das conversas cotidianas dos nativos se baseiam em apenas{" "}
          <scan className="font-bold text-base md:text-2xl">2,000 </scan>{" "}
          palavras comuns.
        </p>
      </div>
      {/* <h2 className="w-full py-10 md:py-20 px-4 md:px-24 lg:px-44  text-dk-purple md:text-xl text-base text-center  bg-white " >Antes de aprender estruturas complexas ou gírias raramente usadas no cotidiano, neste curso, <scan className="font-bold ">você irá aprender a: </scan></h2> */}
      {/* <LearningFirst /> */}

      <div className="bg-white md:w-[70%] rounded-xl text-dk-purple px-8 md:px-14 py-9 flex flex-col gap-8  shadow-md drop-shadow-blueish">
        <div className="flex items-center gap-2 justify-center">
          <img src={numTwo} className="w-[55px] md:w-[78px]" />
          <h2 className="text-base md:text-2xl font-bold">
            Converse com outras pessoas!
          </h2>
        </div>
        <p className="text-sm md:text-xl text-center lg:px-16">
          A verdadeira fluência só é alcançada através da aplicação prática de
          tudo o que você está aprendendo. Existem inúmeros grupos de
          conversação, tanto presenciais quanto online.{" "}
          <scan className="font-bold text-base md:text-xl">
            {" "}
            Totalmente gratuitos!
          </scan>
        </p>
      </div>
      {/* <h2 className="w-full py-10 md:py-20 px-4 md:px-24 lg:px-44  text-dk-purple md:text-xl text-base text-center" >Neste curso, você terá a oportunidade de participar desses grupos e adquirir várias estratégias para melhorar sua conversação e fluência.</h2> */}

      {/* <CommunicationStrategies /> */}

      <div className=" mb-24 bg-white md:w-[70%] rounded-xl text-dk-purple px-8 md:px-14 py-9 flex flex-col gap-8  shadow-md drop-shadow-blueish">
        <div className="flex items-center gap-2 justify-center">
          <img src={numThree} className="w-[55px] md:w-[78px]" />
          <h2 className="text-base md:text-2xl font-bold">
            Consistência e exposição ao <scan className="underline">mesmo</scan>{" "}
            conteúdo!
          </h2>
        </div>
        <p className="text-sm md:text-xl text-center lg:px-16">
          Aprender coisas novas é fácil, mas esquecê-las é ainda mais fácil! Por
          isso, é preciso ter bastante exposição ao mesmo conteúdo, de
          preferência, em contextos diferentes."
        </p>
      </div>
      {/* <h2 className="w-full py-10 md:py-20 px-4 md:px-24 lg:px-44  text-dk-purple md:text-xl text-base text-center" >
                Cada lição do curso permitirá que você pratique o novo conteúdo em todas as modalidades do aprendizado: <scan className="font-bold ">Leitura, escuta e fala. </scan></h2>
            <CourseTools /> */}
    </div>
  )
}

export default CourseLeadFluency
