import React from 'react'
import { AiOutlineArrowRight } from "react-icons/ai"
import { AiFillCheckSquare } from "react-icons/ai"
import { BsThreeDots } from "react-icons/bs"

import { BsArrowDownShort } from "react-icons/bs"


function VerbTense({ tense, children, module }) {
    return (
        <div className=' items-center flex gap-4 md:gap-8 justify-center flex-col md:flex-row '>
            <h1 className='bg-py-purple uppercase text-white text-center text-base md:text-xl rounded-xl md:w-[20%] p-5 '>{module}</h1>
            <div className='hidden md:flex'> <AiOutlineArrowRight color='black' size={32} /></div>
            <div className='flex md:hidden'> <BsArrowDownShort color='black' size={32} /></div>
            <h1 className='bg-py-orange uppercase text-white text-center text-base md:text-xl rounded-xl md:w-[15%] p-5 '>{tense}</h1>
            <div className='hidden md:flex'> <AiOutlineArrowRight color='black' size={32} /></div>
            <div className='flex md:hidden'> <BsArrowDownShort color='black' size={32} /></div>
            <div className='  flex flex-col  py-8 pl-8 pr-4 rounded-9 md:rounded-xl w-full  md:w-[50%] shadow-md bg-white text-dk-purple border border-lt-gray justify-start'>
                <p className='pb-4  text-dk-purple font-bold'>Você aprenderá a:</p>
                {children}
            </div>

        </div>
    )
}


function CourseModules() {

    return (
        <div className=' flex flex-col items-center  gap-8 pt-12  md:py-24 px-2 bg-white border-b-2  '>
            <h1 className='md:w-[80%] text-center  text-base md:text-xl font-bold text-dk-purple'>Antes de explorar estruturas complexas e gírias raramente utilizadas no dia a dia, nos meus cursos de inglês, você irá aprender de uma vez por todas a se comunicar nos três tempos verbais essenciais: presente, passado e futuro. </h1>
            <p className=' md:w-[70%]  text-center text-sm  md:text-xl text-dk-purple'> Acredite, ao dominar com maestria esses três tempos verbais, você será capaz de se sair bem em praticamente qualquer situação!"</p>
            <div className='flex flex-col gap-8 pb-16 lg:pb-2 md:pb-8 pt-8 md:pt-24 w-full md:text-base items-center text-sm '>

                <VerbTense tense={"presente"} module={"CURSO 1"}>
                    <div className='flex gap-2 items-start   '>
                        <AiFillCheckSquare size={20} color='black' />
                        <p className='w-[85%]'> Falar sobre você, sua familia e amigos.</p>
                    </div>
                    <div className='flex gap-2 '>
                        <AiFillCheckSquare size={20} color='black' />
                        <p className='w-[85%]'> Descrever lugares como seu bairro, cidade e país.</p>
                    </div>
                    <div className='flex gap-2  '>
                        <AiFillCheckSquare size={20} color='black' />
                        <p className='w-[85%]'> Conversar sobre sua rotina, hábitos e hobbies.</p>
                    </div>
                    <div className='flex gap-2 '>
                        <AiFillCheckSquare size={20} color='black' />
                        <p className='w-[85%]'> Falar sobre o que está acontecendo no momento.</p>
                    </div>
                    <div className='flex gap-2 '>
                        <AiFillCheckSquare size={20} color='black' />
                        <p className='w-[85%]'> Expressar sua opinião sobre esses e outros assuntos similares.</p>
                    </div>

                </VerbTense>
                <VerbTense tense={"futuro"} module={"CURSO 2"}>
                    <div className='flex gap-2 items-start '>
                        <AiFillCheckSquare size={20} color='black' />
                        <p className='w-[85%]'> Falar sobre seus planos pessoais para a semana, mês e ano.</p>
                    </div>
                    <div className='flex gap-2 '>
                        <AiFillCheckSquare size={20} color='black' />
                        <p className='w-[85%]'> Falar sobre seus planos profissionais para os próximos anos.</p>
                    </div>
                    <div className='flex gap-2 '>
                        <AiFillCheckSquare size={20} color='black' />
                        <p className='w-[85%]'> Fazer previsões sobre como sua vida e o mundo irão mudar.</p>
                    </div>
                    <div className='flex gap-2 '>
                        <AiFillCheckSquare size={20} color='black' />
                        <p className='w-[85%]'> Falar sobre situações hipotéticas que são prováveis de acontecer.</p>
                    </div>
                    <div className='flex gap-2 '>
                        <AiFillCheckSquare size={20} color='black' />
                        <p className='w-[85%]'> Expressar sua opinião sobre esses e outros assuntos similares.</p>
                    </div>

                </VerbTense>

                <VerbTense tense={"passado"} module={"CURSO 3"}>
                    <div className='flex gap-2 items-start '>
                        <AiFillCheckSquare size={20} color='black' />
                        <p className='w-[85%]'> Contar histórias sobre a infância, adolescência e vida adulta.</p>
                    </div>
                    <div className='flex gap-2 '>
                        <AiFillCheckSquare size={20} color='black' />
                        <p className='w-[85%]'> Falar sobre viagens e eventos que participou.</p>
                    </div>
                    <div className='flex gap-2 '>
                        <AiFillCheckSquare size={20} color='black' />
                        <p className='w-[85%]'> Explorar biografias de figuras importantes.</p>
                    </div>
                    <div className='flex gap-2 '>
                        <AiFillCheckSquare size={20} color='black' />
                        <p> Falar sobre suas experiências acadêmicas e profissionais.</p>
                    </div>
                    <div className='flex gap-2 '>
                        <AiFillCheckSquare size={20} color='black' />
                        <p className='w-[85%]'> Expressar sua opinião sobre esses e outros assuntos similares.</p>
                    </div>

                </VerbTense>
                {/* <VerbTense tense={"future"} />
                <VerbTense tense={"past"} /> */}
                <div className='lg:pt-6'> <BsThreeDots color='black' size={32} /></div>
            </div>

        </div >
    )
}

export default CourseModules